export interface IRoute {
  pathName: string;
  showSearch: boolean;
  searchOverlap: boolean;
}

export interface IRoutes {
  [key: string]: IRoute;
}

export const routes: IRoutes = {
  portal: {
    pathName: '/',
    showSearch: false,
    searchOverlap: true,
  },
  login: {
    pathName: '/login',
    showSearch: false,
    searchOverlap: true,
  },
  welcome: {
    pathName: '/welcome',
    showSearch: false,
    searchOverlap: true,
  },
  fleetManagement: {
    pathName: '/fleetmanagement',
    showSearch: true,
    searchOverlap: true,
  },
  map: {
    pathName: '/fleetmanagement/map',
    showSearch: true,
    searchOverlap: true,
  },
  list: {
    pathName: '/fleetmanagement/list',
    showSearch: true,
    searchOverlap: false,
  },
  about: {
    pathName: '/fleetmanagement/about',
    showSearch: false,
    searchOverlap: true,
  },
  documentation: {
    pathName: '/documentation',
    showSearch: false,
    searchOverlap: true,
  },
  cockpit: {
    pathName: 'https://rmcockpit.azurewebsites.net/', //'/cockpit',
    showSearch: false,
    searchOverlap: true,
  },
  academy: {
    pathName: 'https://rm-family.online/login/', //'/academy',
    showSearch: false,
    searchOverlap: true,
  },
  virtualworld: {
    pathName: 'https://virtualworld.rubblemaster.com/', //'/academy',
    showSearch: false,
    searchOverlap: true,
  },
  machineConfigurator: {
    pathName: '/machineConfigurator',
    showSearch: false,
    searchOverlap: true,
  },
  webshop: {
    pathName: 'https://shop.rubblemaster.com/',
    showSearch: false,
    searchOverlap: true,
  },
  digital: {
    pathName: 'https://www.rmdigitalservices.com/', // digital insight
    showSearch: false,
    searchOverlap: true,
  },
// upload: {
  //   pathName: '/upload',
  //   showSearch: false,
  //   searchOverlap: false,
  // },
  // uploadFile: {
  //   pathName: '/uploadFile',
  //   showSearch: false,
  //   searchOverlap: false,
  // },
  notFound: {
    pathName: '*',
    showSearch: false,
    searchOverlap: true,
  },
};
