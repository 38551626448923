import React from 'react';
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleSearch,
  searchMachines,
  resetFilters,
} from '../../actions/searchActions';
import { openLinkWithRewrite, routes } from '../../constants';
import MuiMenuAbout from './MuiMenuAbout';
import MuiMenuList from './MuiMenuList';
import MuiMenuLogout from './MuiMenuLogout';
import MuiMenuLogin from './MuiMenuLogin';
import { useHistory } from 'react-router-dom';
import {
  redirectAzureB2CLogin,
  redirectAzureB2CLogout,
} from '../../actions/authActions';
import {
  Button,
  FormControlLabel,
  Switch,
  Grid,
  Typography,
  ListItemIcon,
} from '@material-ui/core';
import DesktopSearchTextInput from '../Search/DesktopSearchTextInput';
import IgnitionCheckboxes from '../Search/IgnitionCheckboxes';
import MachineStateSelect from '../Search/MachineStateSelect';
import CloseIcon from '@material-ui/icons/Close';
import { IReduxState } from '../../types';
import MobileSearch from '../Search/MobileSearch';
import WarningIcon from '@material-ui/icons/Warning';
import { useTranslation } from 'react-i18next';
import { themeColors } from '../../constants';
import RubbleBreadcrumbs from '../RubbleBreadcrumbs';
import SearchTags from '../Search/SearchTags';
import MuiMenuWelcome from './MuiMenuWelcome';
import MuiMenuPortal from './MuiMenuPortal';
import MuiMenuMap from './MuiMenuMap';
import SettingsIcon from '@material-ui/icons/Settings';
import { fetchAllMachines } from '../../actions/machineActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filter: {
      paddingLeft: theme.spacing(2),
    },
    searchDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    searchMobile: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    error: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
    },
    errorText: {
      marginLeft: theme.spacing(1),
    },
    filterWrapper: {
      position: 'absolute',
      background: themeColors.darkGray,
      zIndex: 1010,
      width: '100%',
      paddingBottom: `${theme.spacing(2)}px`,
      '&$searchOverlap': {
        position: 'relative',
      },
    },
    searchOverlap: {},
    searchButton: {
      padding: '4px 12px',
      height: '40px',
    },
    secondarySearchButton: {
      marginLeft: theme.spacing(1),
    },
  })
);

export default function RubbleBar() {

  // console.log("**************************");
  // console.log("In RubbleBar");
  // console.log("**************************");

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: IReduxState) => state.auth.isAuthenticated
  );
  const history = useHistory();
  const isMenuOpen = Boolean(anchorEl);
  const showSearch = useSelector(
    (state: IReduxState) => state.search.showSearch
  );
  const showSearchError = useSelector(
    (state: IReduxState) => state.search.showSearchError
  );

  const [hideFilter, setChecked] = React.useState(false);
  const { t } = useTranslation();

  const toggleFilter = () => {
    setChecked((prev) => !prev);
    if (hideFilter) {
      dispatch(resetFilters());
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRouteChange = (route: string) => {
    history.push(route);
    handleMenuClose();
  };

  const handleUserLogout = () => {
    dispatch(redirectAzureB2CLogout(routes.portal.pathName));
    handleMenuClose();
  };

  const handleUserLogin = () => {
    dispatch(redirectAzureB2CLogin());
    handleMenuClose();
  };

  const menuId = 'primary-menu';
  const location = history.location.pathname;
  const menuServices = [
    <MenuItem
      key={`menuitem-portal`}
      onClick={() => handleRouteChange(routes.portal.pathName)}
    >
      <MuiMenuPortal isActive={Boolean(location === routes.portal.pathName)} />
    </MenuItem>,
    <MenuItem
      key={`menuitem-welcome`}
      onClick={() => handleRouteChange(routes.welcome.pathName)}
    >
      <MuiMenuWelcome
        isActive={Boolean(location === routes.welcome.pathName)}
      />
    </MenuItem>,
    <MenuItem
      key={`menuitem-map`}
      onClick={() => handleRouteChange(routes.map.pathName)}
    >
      <MuiMenuMap
        isActive={Boolean(location === routes.map.pathName)}
        translationString={'navigation.fleetManagement'}
      />
    </MenuItem>,
    // <MenuItem
    //   key={`menuitem-docuportal`}
    //   onClick={() => openLinkWithRewrite(routes.documentation.pathName)}
    // >
    //   <ListItemIcon>
    //     <SettingsIcon fontSize="small" />
    //   </ListItemIcon>
    //   <Typography variant="inherit">{t('navigation.docuportal')}</Typography>
    // </MenuItem>,
    // <MenuItem
    //   key={`menuitem-cockpit`}
    //   onClick={() => openLinkWithRewrite(routes.cockpit.pathName)}
    // >
    //   <ListItemIcon>
    //     <SettingsIcon fontSize="small" />
    //   </ListItemIcon>
    //   <Typography variant="inherit">{t('navigation.cockpit')}</Typography>
    // </MenuItem>,
    <MenuItem 
      key={`menuitem-logout`} 
      onClick={() => handleUserLogout()}>
      <MuiMenuLogout />
    </MenuItem>,
  ];
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isAuthenticated
        ? location === routes.portal.pathName 
        || location === routes.welcome.pathName
          ? menuServices
          : [
              <MenuItem
                key={`menuitem-portal`}
                onClick={() => handleRouteChange(routes.portal.pathName)}
              >
                <MuiMenuPortal
                  isActive={Boolean(location === routes.portal.pathName)}
                />
              </MenuItem>,
              <MenuItem
                key={`menuitem-map`}
                onClick={() => handleRouteChange(routes.map.pathName)}
              >
                <MuiMenuMap
                  isActive={Boolean(location === routes.map.pathName)}
                />
              </MenuItem>,
              <MenuItem
                key={`menuitem-list`}
                onClick={() => handleRouteChange(routes.list.pathName)}
              >
                <MuiMenuList
                  isActive={Boolean(location === routes.list.pathName)}
                />
              </MenuItem>,
              <MenuItem
                key={`menuitem-about`}
                onClick={() => handleRouteChange(routes.about.pathName)}
              >
                <MuiMenuAbout
                  isActive={Boolean(location === routes.about.pathName)}
                />
              </MenuItem>,
              <MenuItem
                key={`menuitem-logout`}
                onClick={() => handleUserLogout()}
              >
                <MuiMenuLogout />
              </MenuItem>,
            ]
        : [
            <MenuItem
              key={`menuitem-portal`}
              onClick={() => handleRouteChange(routes.portal.pathName)}
            >
              <MuiMenuPortal
                isActive={Boolean(location === routes.portal.pathName)}
              />
            </MenuItem>,
            <MenuItem
              key={`menuitem-welcome`}
              onClick={() => handleRouteChange(routes.welcome.pathName)}
            >
              <MuiMenuWelcome
                isActive={Boolean(location === routes.welcome.pathName)}
              />
            </MenuItem>,
            <MenuItem key={`menuitem-login`} onClick={() => handleUserLogin()}>
              <MuiMenuLogin />
            </MenuItem>,
          ]}
    </Menu>
  );

  const searchError = (
    <div className={classes.error}>
      <WarningIcon color="error" />
      <Typography variant="body1" color="error" className={classes.errorText}>
        {t('search.nothingFound')}
      </Typography>
    </div>
  );

  // Check showSearch prop on route
  let showSearchBar: boolean = false;
  let searchOverlap: boolean = true;

  for (const prop in routes) {
    if (routes[prop].pathName === history.location.pathname) {
      searchOverlap = routes[prop].searchOverlap;
      showSearchBar = routes[prop].showSearch;
    }
  }

  const appBarOnlyBottomShadowStyle = {
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -4px, rgba(0, 0, 0, 0.14) 0px 4px 5px -5px, rgba(0, 0, 0, 0.12) 0px 1px 10px -10px',
  };

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        color="secondary"
        style={appBarOnlyBottomShadowStyle}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          {showSearchBar ? (
            <React.Fragment>
              <div className={classes.searchMobile}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => dispatch(toggleSearch())}
                >
                  {!showSearch ? <SearchIcon /> : <CloseIcon />}
                </IconButton>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => dispatch(fetchAllMachines())}
                >
                  <RefreshIcon />
                </IconButton>
                {showSearchError && searchError}
              </div>
              <div className={classes.searchDesktop}>
                <div className={classes.search}>
                  <DesktopSearchTextInput />
                </div>
                <Button
                  className={classes.searchButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(searchMachines());
                  }}
                >
                  {t('search.search')}
                </Button>
                <FormControlLabel
                  className={classes.filter}
                  control={
                    <Switch
                      color="primary"
                      checked={hideFilter}
                      onChange={toggleFilter}
                    />
                  }
                  label={t('search.filters')}
                />
                <Button
                  className={classes.secondarySearchButton}
                  variant="contained"
                  color="secondary"
                  title={t('search.refresh')}
                  onClick={() => {
                    dispatch(fetchAllMachines());
                  }}
                >
                  <RefreshIcon />
                </Button>
                {showSearchError && searchError}
              </div>
            </React.Fragment>
          ) : null}
          {history.location.pathname.includes('machine') && (
            <RubbleBreadcrumbs current={history.location.pathname} />
          )}
          <div className={classes.grow} />
        </Toolbar>
        {renderMenu}
      </AppBar>
      {hideFilter && showSearchBar && (
        <div
          className={
            searchOverlap
              ? `${classes.filterWrapper}`
              : `${classes.filterWrapper} ${classes.searchOverlap}`
          }
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
          >
            <IgnitionCheckboxes />
            <MachineStateSelect />
            <SearchTags />
          </Grid>
        </div>
      )}
      <MobileSearch />
    </div>
  );
}
