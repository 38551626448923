import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Switch,
  FormControlLabel,
  Button,
  Typography,
} from '@material-ui/core';
import {
  themeColors,
  createPdfReportHeader,
  createPdfReportFooter,
  chartColors,
} from '../../constants';
import moment from 'moment';
import DatesPopOver from './DatesPopOver';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { IReduxState, IErrorExtended, IError } from '../../types';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  CREATE_PDF_REPORT,
  CREATE_PDF_REPORT_SUCCESS,
} from '../../actions/types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SkeletonLoaderTable from './SkeletonLoaderTable';

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: IErrorExtended[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any[]) => el[0]);
}

type Order = 'asc' | 'desc';

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]: number | string },
  b: { [key in K]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  class: string;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  showErrrorFrequency: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, showErrrorFrequency } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const { t } = useTranslation();

  const headCells: HeadCell[] = [
    {
      id: 'dateTime',
      disablePadding: false,
      label: t('machine.dateTime'),
      class: classes.tableCellFixedMedium,
    },
    {
      id: 'errorCode',
      disablePadding: false,
      label: t('machine.errorCode'),
      class: classes.tableCellFixedSmall,
    },
    {
      id: 'group',
      disablePadding: false,
      label: t('machine.group'),
      class: classes.tableCellFixedSmall,
    },
    {
      id: 'hoursOfCrusherOperation',
      disablePadding: false,
      label: t('machine.crusherOperation'),
      class: classes.tableCellFixedSmall,
    },
    {
      id: 'description',
      disablePadding: false,
      label: t('machine.description'),
      class: classes.tableCellFill,
    },
  ];

  const headCellsExtended: HeadCell[] = [
    {
      id: 'dateTime',
      disablePadding: false,
      label: t('machine.dateTime'),
      class: classes.tableCellFixedMedium,
    },
    {
      id: 'errorCode',
      disablePadding: false,
      label: t('machine.errorCode'),
      class: classes.tableCellFixedSmall,
    },
    {
      id: 'group',
      disablePadding: false,
      label: t('machine.group'),
      class: classes.tableCellFixedSmall,
    },
    {
      id: 'description',
      disablePadding: false,
      label: t('machine.description'),
      class: classes.tableCellFill,
    },
    {
      id: 'frequency',
      disablePadding: false,
      label: t('machine.frequency'),
      class: classes.tableCellFixedSmall,
    },
  ];

  const tableHead = showErrrorFrequency ? headCellsExtended : headCells;

  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        {tableHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 300,
      margin: `${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        margin: `${theme.spacing(2)}px`,
      },
    },
    buttonWrapper: {
      marginBottom: `${theme.spacing(2)}px`,
      marginRight: 8,
      paddingTop: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        marginBottom: 0,
        marginRight: `${theme.spacing(2)}px`,
      },
      alignItems: 'center',
      justifyContent: 'center',
      float: 'right',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    chipRed: {
      backgroundColor: themeColors.red,
      color: themeColors.white,
    },
    chipGreen: {
      backgroundColor: themeColors.green,
      color: themeColors.white,
    },
    tableControls: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
    tableRow: {
      height: 80,
      width: '100%',
    },
    helperText: {
      padding: theme.spacing(2),
    },
    tableCellFixedSmall: {
      width: '15%',
      minWidth: '130px',
    },
    tableCellFixedMedium: {
      width: '20%',
      minWidth: '160px',
    },
    tableCellFill: {},
  })
);

interface IProps {
  startRowsPerPage?: number;
}

export default function MachineErrors(props: IProps) {
  const startRowsPerPage = props.startRowsPerPage ? props.startRowsPerPage : 0;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['translation', 'errorcodes']);

  // console.log("***************************************************************************");
  // console.log("In MachineErrors 24.11.19");
  // console.log("***************************************************************************");

  const useBlacklist : boolean = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.useBlacklist
  );
  const errorBlackList = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.errorBlackList
  );
  const info = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.info
  );
  const machineErrors = useSelector(
    (state: IReduxState) => state.machineDetails.errorCodes
  );

  // const machineErrorsFiltered = useSelector(
  //   (state: IReduxState) => state.machineDetails.errorCodes //filterErrors(state.machineDetails.errorCodes, useBlacklist, errorBlackList)
  // );

  // console.log("****************************************");
  // console.log("useBlacklist : " + useBlacklist);
  // console.log("errorBlackList : " + errorBlackList);
  // console.log("########################################");
  // console.warn(info);
  // console.log("########################################");
  // console.log("machineErrors : ");
  // console.log(machineErrors);
  // console.log("****************************************");

  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );
  const isFetchingTelemetry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );
  const search = useSelector(
    (state: IReduxState) => state.machineDetails.search
  );
  // const machineDetails = useSelector(
  //   (state: IReduxState) => state.machineDetails
  // );
  // console.log("machineDetails:");
  // console.log(machineDetails);

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('dateTime');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(startRowsPerPage);
  const machine = useSelector(
    (state: IReduxState) => state.machineDetails.machine
  );
  const [showErrorFrequency, setShowErrorFrequency] = React.useState(false);

  // console.log("Machine:");
  // console.log(machine);


  const errors: IErrorExtended[] = !!machineErrors
    ? machineErrors.map((item: any) => {
        // #40916 
        // Wenn eine Message mit SPN.FMI kommt, ...
        let hasSPN = !!item.engineErrorSPN
        // console.log("hasSPN0 : " +  hasSPN);
        // console.log(item);
        // ... muss in der Spalte "Error Code" 999 angezeigt werden
        const errorCode = hasSPN ? '999' : item.errorCode;
        // ...In der Spalte Group ist dann der Text "Motor (SPN.FMI)" anzuzeigen
        const group = hasSPN ? 'Motor (SPN.FMI)' : t(`errorcodes:${errorCode}.group`, '-');
        // ... In der Spalte Description ist der SPN.FMI numerisch und falls vorhanden, der zugehörige Beschreibungstext, anzuzeige.
        const description = hasSPN ? (item.engineErrorSPN + '.' + item.engineErrorFMI) : t(`errorcodes:${errorCode}.description`, '-');
        return {
          ...item,
          // group: t(`errorcodes:${errorCode}.group`, '-'),
          // description: t(`errorcodes:${errorCode}.description`, '-'),
          group: group,
          description: description,
        };
      })
    : [];

  const pdfErrors = errors
    // sorted descending
    .sort((a, b) => moment(b.dateTime).diff(a.dateTime))
    .map((item) => {
      const filteredErrors = errors.filter(
        (_) => _.errorCode === item.errorCode
      );
      const frequency = filteredErrors.length;
      return {
        ...item,
        dateTime: moment(item.dateTime).format(`DD.MM.YYYY HH:mm`),
        errorCode: item.errorCode,
        frequency,
      };
    });

  const errorsExtended = errors.map((item) => {
    const filteredErrors = errors.filter((_) => _.errorCode === item.errorCode);
    const frequency = filteredErrors.length;
    const dateArray = filteredErrors.map((item) => item.dateTime);
    return {
      dateTime: dateArray,
      errorCode: item.errorCode,
      frequency,
      group: item.group,
      description: item.description,
    };
  });

  const removeDuplicates = (arr: any[]) => {
    const key = 'errorCode';
    return [...new Map(arr.map((error) => [error[key], error])).values()];
  };

  let rows = showErrorFrequency ? removeDuplicates(errorsExtended) : errors;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleSwitch = () => {
    setShowErrorFrequency((prev) => !prev);
  };

  const createReport = () => {
    dispatch({ type: CREATE_PDF_REPORT });
    const doc = new jsPDF('landscape');
    const reportTitle = `${t('report.errorReport')}`;
    const reportTimeframe = `${moment(search.startDate).format(
      'DD.MM.YYYY HH:mm'
    )} ${t('report.till')} ${moment(search.endDate).format(
      'DD.MM.YYYY HH:mm'
    )}`;

    const serialNumber = !!machine.serialNumber ? machine.serialNumber : '';

    const reportFileName = `${serialNumber} - ${moment(
      search.startDate
    ).format('DD.MM.YYYY HHmm')} ${t('report.till')} ${moment(
      search.endDate
    ).format('DD.MM.YYYY HHmm')}`;

    // TODO: The library used for creating a pdf table on client side (jsPdf AutoTable) has no types.
    // @ts-ignore
    doc.autoTable({
      headStyles: {
        fillColor: themeColors.darkGray,
        fontSize: 14,
        textColor: chartColors.white,
        halign: 'center',
        minCellWidth: 50,
      },
      bodyStyles: { fontSize: 12, halign: 'center' },
      didDrawPage: () => {
        createPdfReportHeader(doc, reportTitle, reportTimeframe);
        createPdfReportFooter(
          doc,
          serialNumber,
          t('report.page'),
          doc.internal.getNumberOfPages(),
          null
        );
      },
      margin: { top: 40, right: 16, bottom: 20, left: 16 },
      showFoot: 'everyPage',
      rowPageBreak: 'auto',
      columns: [
        { header: `${t('report.errorCode')}`, dataKey: 'errorCode' },
        { header: `${t('report.dateAndTime')}`, dataKey: 'dateTime' },
        {
          header: `${t('report.crusherOperation')}`,
          dataKey: 'hoursOfCrusherOperation',
        },
        { header: `${t('report.description')}`, dataKey: 'description' },
        {
          header: `${t('machine.errorFrequency')}`,
          dataKey: 'frequency',
        },
      ],
      body: pdfErrors,
    });

    /*
     * totalNumberOfPages are only available after all pages are created
     * manually iterating over every page to add totalNumberOfPages
     * */
    const totalNumberOfPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalNumberOfPages; i++) {
      doc.setPage(i);
      doc
        .setFontSize(12)
        .text(
          `${t('report.page')} ${i} / ${totalNumberOfPages}`,
          doc.internal.pageSize.getWidth() - 32,
          doc.internal.pageSize.getHeight() - 16
        );
    }

    dispatch({ type: CREATE_PDF_REPORT_SUCCESS });

    doc.save(`${reportTitle} - ${reportFileName}.pdf`);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        style={{
          boxShadow: isHybrid
            ? `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`
            : 'none',
        }}
      >
        {!isFetchingTelemetry ? (
          <TableContainer>
            <Table aria-labelledby="tableTitle">
              <EnhancedTableHead
                showErrrorFrequency={showErrorFrequency}
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {machineErrors !== null &&
                  machineErrors.length !== 0 &&
                  stableSort(rows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {
                      const labelId = `enhanced-table-${index}`;

                      const date = showErrorFrequency ? (
                        row.frequency > 1 ? (
                          <DatesPopOver content={row.dateTime} />
                        ) : row.dateTime ? (
                          // @ts-ignore
                          moment(row.dateTime[0]).format(`DD.MM.YYYY HH:mm`)
                        ) : (
                          ''
                        )
                      ) : (
                        moment(row.dateTime).format(`DD.MM.YYYY HH:mm`)
                      );
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={`${row.errorCode}-${index}`}
                          className={classes.tableRow}
                        >
                          <TableCell align="center">{date}</TableCell>
                          <TableCell id={labelId} scope="row" align="center">
                            {row.errorCode}
                          </TableCell>
                          <TableCell scope="row" align="center">
                            {row.group}
                          </TableCell>
                          {!showErrorFrequency && (
                            <TableCell align="center">
                              {row.hoursOfCrusherOperation}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {!showErrorFrequency
                            ? row.description
                            : row.errorCode === '999'
                              ? `${t('machine.generalEngineError')}`
                              : row.description
                            }
                          </TableCell>
                          {showErrorFrequency && (
                            <TableCell align="center">
                              {row.frequency}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                {machineErrors === null && (
                  <TableRow>
                    <TableCell align="center">{t('graph.noData')}</TableCell>
                  </TableRow>
                )}
                {machineErrors !== null && machineErrors.length === 0 && (
                  <TableRow>
                    <TableCell>{t('machine.noErrorDuringTime')}</TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <SkeletonLoaderTable />
        )}
        <div className={classes.tableControls}>
          <FormControlLabel
            control={
              <Switch
                checked={showErrorFrequency}
                onChange={toggleSwitch}
                value="switchChecked"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={t('translation:machine.errorFrequency')}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 50]}
            labelRowsPerPage={t('translation:list.rowsPerPage')}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Button
            variant="outlined"
            color="primary"
            disabled={errors.length === 0}
            startIcon={<GetAppIcon />}
            onClick={() => createReport()}
          >
            {t('report.errorReport')}
          </Button>
        </div>
        {!!machineErrors && machineErrors?.length >= 1000 && (
          <Typography className={classes.helperText} variant="body2">
            {`${t('machine.pleaseNote')}: ${t(
              'machine.errorTruncationMessage'
            )}`}
          </Typography>
        )}
      </Paper>
    </div>
  );
}
// function filterErrors(errorCodes: IError[] | null, useBlacklist: boolean, errorBlackList: string | undefined): IError[] | null {
//   console.debug("In filterErrors(" + useBlacklist + ")");
//   console.debug(errorCodes);
//   console.debug(errorBlackList);

//   // useBlacklist == false oder errorBlackList leer
//   if(useBlacklist !== true || !errorBlackList || errorBlackList === undefined){
//     console.log("Result:errorCodes");
//     console.log(errorCodes);

//     return errorCodes;
//   }

//   // errorCodes leer
//   if(errorCodes === null){
//     console.log("Result: null");
//     return null;
//   }

//   let filteredErrors : IError[] = [];
//   // errorBlackList (,-separierter String) in echte String-Liste umwandeln
//   var blackList = errorBlackList.split(", "); 

//   console.log(blackList);

//   for (let i = 0; i < errorCodes.length; i++) {
//     if(!!errorCodes[i].errorCode){
//       const errorCode = errorCodes[i].errorCode?.toString();
//       if(!!errorCode){
//         if (!blackList.includes(errorCode) ) {
//           filteredErrors.push(errorCodes[i]);
//         }
//       }
//     }
//   }
//   console.log("Result:filteredErrors");
//   console.log(filteredErrors);

//   return filteredErrors;
// }

