import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { chartColors, themeColors } from '../../../constants';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IReduxState } from '../../../types';
import SkeletonLoaderCardSmall from '../SkeletonLoaderCardSmall';
import TotalHours from '../TotalHours';
import { log } from 'console';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      flexGrow: 1,
    },
    card: {
      height: 500,
      [theme.breakpoints.up('xs')]: {
        margin: `${theme.spacing(4)}px 0`,
      },
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px`,
      },
    },
    cardContent: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(2)}px 0`,
      },
    },
    actionsArena: {
      position: 'relative',
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
        justifyContent: 'start',
      },
    },
    lastUpdate: {
      position: 'absolute',
      left: theme.spacing(1),
      top: 0,
      color: themeColors.white,
    },
    title: {
      paddingTop: '1rem',
      marginBottom: '-1rem',
    },
    gaugeWrapper: {
      height: 175,
      width: 200,
    },
  })
);

export default function EngineUtilizationGauge() {
  const classes = useStyles();
  const { t } = useTranslation(['translation']);
  const averageEngineUtilization = useSelector(
    (state: IReduxState) => state.machineDetails.averageEngineUtilization
  );

  const lastEngineUtilization = !!averageEngineUtilization.value
    ? averageEngineUtilization.value.toFixed(2)
    : 0;
  const isFetchingTelemetry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );
  // default: false
  const isJawCrusher = useSelector(
    (state: IReduxState) => state.machineDetails.machine.machineType.isJawCrusher ?? false
  )

  // console.log("***************************************************************************");
  // console.log("In EngineUtilizationGauge");
  // console.log("***************************************************************************");

  // console.log("updatedAt");
  // console.log(
  //   !!averageEngineUtilization.timestamp
  //                     ? moment
  //                         .utc(averageEngineUtilization.timestamp)
  //                         .local()
  //                         .format(`DD.MM.YYYY HH:mm`)
  //                     : '-'
  // );

  const averageEngineLoadsTxt = t('graph.averageEngineLoads');
  const averageEnergyUtilizationTxt = t('graph.averageEnergyUtilization');
  const loadsTxt = t('graph.loads');
  const utilizationTxt = t('graph.utilization');

  // console.log("averageEngineLoadsTxt : " + averageEngineLoadsTxt);
  // console.log("averageEnergyUtilizationTxt : " + averageEnergyUtilizationTxt);
  // console.log("loadsTxt : " + loadsTxt);
  // console.log("utilizationTxt : " + utilizationTxt);
  // console.log(t('graph.averageEngineLoads'));
  // console.log(t('graph.averageEnergyUtilizationTxt'));

  const graphData = [
    {
      id: 'empty',
      color: themeColors.darkGray,
      value: 100 - +lastEngineUtilization,
      label: 'empty',
    },
    {
      id: 'averageEngineUtilization',
      color: chartColors.rmYellow,
      value: +lastEngineUtilization,
      label: 'Engine Utilization',
    },
  ];

  const graphDataLoads = [
    {
      id: 'empty',
      color: themeColors.darkGray,
      value: 100 - +lastEngineUtilization,
      label: 'empty',
    },
    {
      id: 'averageEngineUtilization',
      color: chartColors.rmYellow,
      value: +lastEngineUtilization,
      label: 'Engine Loads',
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Card className={classes.cardContent}>
          <CardContent
            className={classes.actionsArena}
            style={{
              paddingBottom: 0,
              paddingTop: isFetchingTelemetry ? '1rem' : '2rem',
            }}
          >
            {!isFetchingTelemetry ? (
              <React.Fragment>
                <Typography className={classes.lastUpdate} variant="caption">
                  {`${t('graph.lastReceivedValue')}: ${
                    !!averageEngineUtilization.timestamp
                      ? moment
                          .utc(averageEngineUtilization.timestamp)
                          .local()
                          .format(`DD.MM.YYYY HH:mm`)
                      : '-'
                  }`}
                </Typography>
                <Typography
                  className={classes.title}
                  align="center"
                  variant="h5"
                >
                  {averageEnergyUtilizationTxt}
                </Typography>
                {averageEngineUtilization.value !== null ? (
                  <React.Fragment>
                    <div className={classes.gaugeWrapper}>
                      <ResponsivePie
                        data={graphDataLoads}
                        enableRadialLabels={false}
                        enableSlicesLabels={false}
                        isInteractive={false}
                        startAngle={90}
                        endAngle={-90}
                        innerRadius={0.5}
                        padAngle={1}
                        cornerRadius={3}
                        colors={(d: any) => d.color}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 0.6]],
                        }}
                        slicesLabelsSkipAngle={10}
                        slicesLabelsTextColor={themeColors.white}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                      />
                    </div>
                    <Typography
                      align="center"
                      variant="body1"
                      style={{ color: `${graphData[1].color}` }}
                    >
                      {graphData[1].value} % 
                      {/* {isJawCrusher ? loadsTxt : utilizationTxt} */}
                      {loadsTxt}
                    </Typography>
                  </React.Fragment>
                ) : (
                  <Typography
                    align="center"
                    variant="h5"
                    style={{
                      color: `${graphData[1].color}`,
                      marginTop: '2rem',
                    }}
                  >
                    {t('graph.noData')}
                  </Typography>
                )}
                <TotalHours />
              </React.Fragment>
            ) : (
              <SkeletonLoaderCardSmall />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
