import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import rmBackground from '../../assets/img/rm_digital_solutions.jpg';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { redirectLogin, fetchUserSession } from '../../actions/authActions';
import ServiceCard from './ServiceCard';
import { useHistory } from 'react-router';
import { openLinkWithRewrite, routes } from '../../constants';
import rmWebIcon from '../../assets/img/web.png';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vH - 96px)',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vH - 96px)',
    },
    background: `url(${rmBackground})`,
    backgroundPosition: 'top',
    backgroundSize: '600px 600px',
    [theme.breakpoints.up('sm')]: {
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    },
    position: 'relative',
  },
  container: {
    position: 'relative',
    [theme.breakpoints.up('xl')]: {
      minWidth: '1900px',
      top: '-40px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cardsWrapper: {
    maxWidth: 1680,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
    // [theme.breakpoints.up('sm')]: {
    //   flexDirection: 'column',
    // },
  },
  // firstLineCards: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   justifyContent: 'center',
  //   height: '280px',
  // },
  headerWrapper: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    maxWidth: 1680,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      textAlign: 'inherit',
    },
  },
  titleWrapper: {
    margin: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      width: `calc(100VW - ${theme.spacing(16)}px)`,
      // margin: 0,
    },
    [theme.breakpoints.up('xl')]: {
      // position: 'absolute',
      position: 'relative',
      width: 'auto',
      top: '-80px',
      // left: `${theme.spacing(4)}px`,
      // top: `-${theme.spacing(4)}px`,
    },

  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    letterSpacing: theme.spacing(1),
  },
  title1: {
    marginRight: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontFamily:
      '"EurostileLTStdDemi", "Jura", "Roboto", "Helvetica", "Arial", sans-serif',
    letterSpacing: '4px',
    transform: 'scale(1, 0.9)',
  },
  // title2: {
  //   fontFamily:
  //     '"EurostileLTStd", "Jura", "Roboto", "Helvetica", "Arial", sans-serif',
  //   transform: 'scaleX(1.2)',
  //   WebkitTransform: 'scaleX(1.2)',
  //   display: 'inline-block',
  //   marginLeft: '32px',
  // },
  subtitleWrapper: {
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'EurostileNextLTPro',
    textTransform: 'uppercase',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2),
    textDecoration: 'none', // Entfernt Unterstrich vom Link
    color: 'inherit',       // Verwendet die Standard-Textfarbe
  },
  logo: {
    height: '40px', // Passe die Größe des Icons an
    width: '40px',
    position: 'relative',
    top: '18px',
    cursor: 'pointer',
    marginRight: theme.spacing(1), // Platz zwischen Icon und Text
    [theme.breakpoints.up('xs')]: {
      height: '25px', // Passe die Größe des Icons an
      width: '25px',
      top: '3px',
    },
  },
  linkText: {
    color: 'inherit', // Verwendet Standard-Textfarbe
    textDecoration: 'none', // Entfernt Unterstrich vom Link
  },
  logoText: {
    color: 'inherit',
  },
  // firstLineCardsHelper: {
  //   display: 'none',
  //   [theme.breakpoints.up('lg')]: {
  //     display: 'block',
  //     width: '225px',
  //     margin: theme.spacing(4),
  //   },
  // },
}));

export default function Portal() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  React.useEffect(() => {
    dispatch(fetchUserSession());
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerWrapper}>
          <div className={classes.titleWrapper}>
            <div className={classes.title}>
              <Typography
                className={classes.title1}
                variant="h2"
                color="textPrimary"
              >
                {t('login.title1')}
              </Typography>
              {/* <Typography
                className={classes.title2}
                variant="h3"
                color="textPrimary"
              >
                {t('login.title2')}
              </Typography> */}
            </div>
            <div className={classes.subtitleWrapper}>
              <Typography
                className={classes.subtitle}
                variant="h5"
                color="textPrimary"
              >
                {t('login.subtitle1')}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.cardsWrapper}>
          {/* <div className={classes.firstLineCardsHelper} /> */}
          <ServiceCard
            type="fleetmanagement"
            cardTitle={t('login.fleetManagement')}
            loginButtonLabel={t('login.activated')}
            isActive={true}
            isAvailable={true}
            onClickHandler={() => dispatch(redirectLogin(routes.map.pathName))
            }
          />
          <ServiceCard
            type="productConfigurator"
            cardTitle={t('login.machineConfigurator')}
            isActive={true}
            isAvailable={true}
            loginButtonLabel={t('login.activated')}
            onClickHandler={() => openLinkWithRewrite(routes.machineConfigurator.pathName)}
          />
          <ServiceCard
            type="webshop"
            cardTitle={t('login.webShop')}
            loginButtonLabel={t('login.activated')}
            isActive={true}
            isAvailable={true}
            onClickHandler={() => openLinkWithRewrite(routes.webshop.pathName)}
          />
          <ServiceCard
            type="academy"
            cardTitle={t('login.academy')}
            isActive={true}
            isAvailable={true}
            // onClickHandler={() => history.push(routes.welcome.pathName)}
            onClickHandler={() => openLinkWithRewrite(routes.academy.pathName)}
          />
        </div>

        <div className={classes.cardsWrapper}>
          <ServiceCard
            type="documentation"
            cardTitle={t('login.documentation')}
            // loginButtonLabel={t('login.activated')}
            isActive={true}
            isAvailable={true}
            onClickHandler={() => openLinkWithRewrite(routes.documentation.pathName)
            }
          />
          <ServiceCard
            type="cockpit"
            cardTitle={t('login.cockpit')}
            // loginButtonLabel={t('login.activated')}
            isActive={true}
            isAvailable={true}
            onClickHandler={() => openLinkWithRewrite(routes.cockpit.pathName)}
          />
          <ServiceCard
            type="virtualworld"
            isActive={true}
            isAvailable={true}
            // loginButtonLabel={t('login.activated')}
            onClickHandler={() => openLinkWithRewrite(routes.virtualworld.pathName)}
          />
          <ServiceCard
            type="digital"
            isActive={true}
            isAvailable={true}
            onClickHandler={() => openLinkWithRewrite(routes.digital.pathName)}
          />
        </div>

        <div className={classes.subtitleWrapper}>
          <Typography
            className={classes.subtitle}
            variant="h5"
            color="textPrimary"
          >
            <div className={classes.logoWrapper}>
              <a href="https://www.rubblemaster.com" target="_blank" rel="noopener noreferrer" className={classes.linkText}>
                <img
                  src={rmWebIcon}
                  alt="Rubblemaster Logo"
                  className={classes.logo}
                />
                <span className={classes.logoText}>
                  {t('login.subtitle2')}
                </span>
            </a>
            </div>
          </Typography>
        </div>

      </div>
    </div>
  );
}
